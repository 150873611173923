const axios = require('axios').default;

const PREFIXES = [
	"meeting_room_",
	"company_",
	"title_",
	"reservation_start_time_",
	"reservation_end_time_",
	"seminar_start_time_",
	"seminar_end_time_"
];
const ATTR = "div[koptkey\\.text*='";
export class MeetingInfo
{
	constructor(id,nodes)
	{
		this._id = id;
		this._parse(nodes);
	}
	_parse(nodes)
	{
		const roomNode = nodes.querySelector("div[koptkey\\.text='" + PREFIXES[0] + this.id+"']"),
			compNode = nodes.querySelector("div[koptkey\\.text='" + PREFIXES[1] + this.id+"']"),
			titleNode = nodes.querySelector("div[koptkey\\.text='" + PREFIXES[2] + this.id+"']"),
			resStartNode = nodes.querySelector("div[koptkey\\.text='" + PREFIXES[3] + this.id+"']"),
			resEndNode = nodes.querySelector("div[koptkey\\.text='" + PREFIXES[4] + this.id+"']"),
			semStartNode = nodes.querySelector("div[koptkey\\.text='" + PREFIXES[5] + this.id+"']"),
			semEndNode = nodes.querySelector("div[koptkey\\.text='" + PREFIXES[6] + this.id+"']");

		this._roomName = roomNode ? roomNode.textContent : "";
		this._company = compNode ? compNode.textContent : "";
		this._title = titleNode ? titleNode.textContent : "";
		this._resStart = resStartNode ? resStartNode.textContent : "";
		this._resEnd = resEndNode ? resEndNode.textContent : "";
		this._semStart = semStartNode ? semStartNode.textContent : "";
		this._semEnd = semEndNode ? semEndNode.textContent : "";


		this._enableStart = this._getEnableTime(true) == "sem" ? this._semStart : this._resStart;
		this._enableEnd = this._getEnableTime(false) == "sem" ? this._semEnd : this._resEnd;
		
		
	}
	_getEnableTime(start){
		if(start)
		{
			if(this._semStart != "" && this._semStart != "-") return "sem";
			else return "res";	
		}
		else
		{
			if(this._semEnd != "" && this._semEnd != "-") return "sem";
			else return "res";	
		}
	}
	get company(){return this._company;}
	get room(){return this._roomName;}
	get title(){return this._title;}
	get startMins(){
		const tmp = this._enableStart.split(":");
		return parseInt(tmp[0]) * 60 + parseInt(tmp[1]);
	}
	get endMins(){
		const tmp = this._enableEnd.split(":");
		return parseInt(tmp[0]) * 60 + parseInt(tmp[1]);
	}
	get startHour(){ return this._enableStart;}
	get endHour(){return this._enableEnd;}
	get enable(){
		if(this._roomName == '' || this._roomName == "-" || this._enableStart == "" || this._enableStart == "-") return false;
		return true;
	}
	inSpan(current)
	{
		return this.endMins >= current;
	}
	inRange(current)
	{
		const t = current[0] * 60 + current[1];
		if(this.startMins <= t && this.endMins >= t) return true;
		return false;
	}
	get id(){return this._id;}
}
const MAX_LENGTH = 7;
export class MeetingInfoDatalist
{
	constructor(path)
	{
		this._path = path;
	}
	async load(id)
	{
		const res = await axios.get(this._path+"?id=" + id,{responseType:"document"});

		if(res.data && res.data.querySelectorAll)
		{
			// const latestId = this._getLatestId(res);
			this._parse(res);
		}
	}
	_parse(res)
	{
		this._data = [];
		const mrs = res.data.querySelectorAll(ATTR + PREFIXES[0] + "']"),
			lnode = mrs[mrs.length - 1],
			isError = res.data.querySelector("div[koptkey\\.text='error']").textContent == "1",
			atval = mrs.length == 0 ? 0 : parseInt(lnode.getAttribute("koptkey.text").replace(PREFIXES[0],""));
		
		if(atval == 0 || isError) return;

		
		const tmp = [];
		
		for(let i = 1; i <= atval;i++)
		{
			const dnode = res.data.querySelectorAll("div[koptkey\\.text$=_" + i.toString()),
				midata = new MeetingInfo(i,res.data);
			if(midata.enable)
			{
				tmp.push(midata);
			}
		}
		
		tmp.sort((a,b)=>{
			if(a.startMins > b.startMins) return 1;
			return -1;
		});
		
		this._data = tmp;
		this._createPageData();

		console.log(this._pageData);

	}
	_createPageData()
	{
		this._pageData = [];
		let cnt = 0;
		this._pageData[cnt] = [];
		const tmp = Array.from(this._data);
		tmp.forEach((md)=>{
			this._pageData[cnt].push(md);
			if(this._pageData[cnt].length >= MAX_LENGTH)
			{
				cnt ++;
				this._pageData[cnt] = [];
			}
		})
		if(this._pageData[cnt].length == 0) this._pageData.splice(cnt,1);
	}
	fix(currentTime)
	{
		const len = this.data.length;
		const t = currentTime[0] * 60 + currentTime[1];
		const d = this.data.filter((md)=> md.inSpan(t));
		
		this._data = d;
		this._createPageData();
		return this._data.length != len;
	}
	get pageData(){return this._pageData;}
	get data(){return this._data;}
}