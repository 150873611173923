<template>
	<div class="meetingInfo" v-if="data != null" :class="[direction,{current:data.inRange(current)}]">
		<p class="meetingInfo__hours">
			<span>{{data.startHour}}</span>
			<span class="tilda">～</span>
			<span>{{data.endHour}}</span>
		</p>
		<div class="meetingInfo__detail">
			<p class="meetingInfo__title">{{data.title}}</p>
			<p class="meetingInfo__room">{{data.room}}</p>
			<p class="meetingInfo__company">{{data.company}}</p>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	
	.meetingInfo{
		display: grid;
		color:#fff;
		width: 100%;
		border: 2px solid #464E86;
		background: #1B2141;
		box-shadow: 10px 10px 20px #000;
		flex-shrink: 0;
		&__hours{
			display: flex;
			height: 100%;
			flex-direction: column;
			font-family: 'Roboto', sans-serif;
			background: #0F1434;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			grid-row: 1/2;
			font-size: 37px;
			width: 100%;
		}
		&__room{
				font-size: 26px;
				font-weight: bold;
				color: #1B2141;
				background: #fff;
				padding: 0 20px;
				display: flex;
				align-items: center;
				border-radius: 2px;
				position: relative;
				flex-shrink: 0;
				height: 52px;
				&:after{
					position: absolute;
					right: -20px;top:0;
					content: url("@/assets/img/tail.svg");
				}
			}
		&__detail{
			display: grid;
			
		}
		&.current{
			.meetingInfo__hours{
				background: #1B276B;
			}
			.meetingInfo__detail{
				background: #2C41AC;
			}
		}
		&.horizontal{
			grid-template-columns: 328px 1fr;
			
			.meetingInfo__hours{
				flex-direction: row;
			}
			.meetingInfo__detail{
				min-height: 110px;
				padding: 0 46px 0 35px;
				grid-template-columns: 867px minmax(120px, auto) 1fr;
				align-items: center;
			}
			.meetingInfo__room{
				margin-right: 40px;
				
			}
			.meetingInfo__title{
				font-size: 30px;
				width: 100%;
				min-height: 70px;
				display: flex;
				align-items: center;
				padding-right: 20px;
			}
			.meetingInfo__company{
				font-size: 23px;
			}
		}
		&.vertical{
			grid-template-columns: 173px 1fr;

			.meetingInfo__detail{
				padding:20px 42px 22px;
				grid-template-rows: 1fr 52px;
				grid-template-columns: auto 1fr;
				gap:20px 40px;
			}
			.meetingInfo__title{
				grid-row: 1/2;
				grid-column: 1/3;
				font-size: 35px;
				line-height: 50px;
				min-height: 100px;
				display: flex;
				align-items: center;
			}
			.meetingInfo__room{
				grid-row: 2/3;
				grid-column: 1/2;
				
			}
			.meetingInfo__company{
				grid-row: 2/3;
				grid-column: 2/3;
				font-size: 20px;
				display: flex;
				align-items: center;

			}
			.meetingInfo__hours{
				
				
				gap:12px 0;
				& .tilda{
					transform: rotate(90deg);
				}
			}
		}
	}
</style>
<script>
export default{
	props:{
		"data":{type:Object,default:null},
		"direction":{type:String,default:"horizontal"},
		"current":{type:Array,default:null}
	},

}
</script>