'use strict';
import "core-js/stable";
import "regenerator-runtime/runtime";
import "@/assets/js/libs.min.js";
export const TrEvent = window.jp.contents.events.Event,
	TrEventDispatcher = window.jp.contents.events.EventDispatcher;
const axios = require('axios').default;
import {XMLUtility} from "./utility";

export class XMLSetting
{
	constructor()
	{
		// super();
	}
	async load(path,error = null)
	{
		const tmp = await axios.get(path,{responseType:"document"}).catch((e)=>{
			if(error) error(e);
		});
		if(tmp)
		{
			this._parse(tmp.data);
		}
	}
	getDataByName(name)
	{
		if(this._data[name] !== undefined)
		{
			return this._data[name];
		}
	}
	
	getValueFromFilterObj(name,filter)
	{
		if(this._data[name] && this._data[name] instanceof Array)
		{
			return this._data[name].find((d)=>{
				return d[filter.key] == filter.value;
			});
			
		}
		return null;
	}
	getValueFromFilter(name,filter)
	{
		if(this._data[name])
		{
			if(this._data[name] instanceof Array) return this._data[name].find(filter);
			return null;
		}
		return null;
	}
	_parse(data)
	{
		this._data = XMLUtility.parseObjectNode(data.querySelector("setting"));
	}
}
