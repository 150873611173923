export class Position
{
	constructor(x,y)
	{
		this._x = x;
		this._y = y;
	}
	clone()
	{
		return new Position(this._x,this._y);
	}
	get x(){return this._x;}
	get y(){return this._y;}
}
export class Utility
{
	static formatDate(date,format = 'YYYY-MM-DD hh:mm:ss')
	{
		format = format.replace(/YYYY/g, date.getFullYear().toString());
		format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).toString().slice(-2));
		format = format.replace(/M/g, (date.getMonth() + 1).toString());
		format = format.replace(/DD/g, ('0' + date.getDate().toString()).slice(-2));
		format = format.replace(/D/g, date.getDate().toString());
		format = format.replace(/hh/g, ('0' + date.getHours().toString()).slice(-2));
		format = format.replace(/mm/g, ('0' + date.getMinutes().toString()).slice(-2));
		format = format.replace(/ss/g, ('0' + date.getSeconds().toString()).slice(-2));
		return format;
	}
	static replaceHanonToSeion(word)
	{
		if(word.match(/[ァィゥェォャュョッ]/))
		{
			word = word.replace("ァ","ア");
			word = word.replace("ィ","イ");
			word = word.replace("ゥ","ウ");
			word = word.replace("ェ","エ");
			word = word.replace("ォ","オ");
			word = word.replace("ャ","ヤ");
			word = word.replace("ュ","ユ");
			word = word.replace("ョ","ヨ");
			word = word.replace("ッ","ツ");
		}	
		
		return word;
	}
	static replaceHiraToKana(str)
	{
		if(str == "")return str;
		const r_arr = str.split("");
		let r_str = "";
		r_arr.forEach((tmp)=>{
			
			tmp = tmp.replace(/[ぁ-ん]/g, function(s) {
				return String.fromCharCode(s.charCodeAt(0) - 0x60);
			});
			tmp = tmp.normalize("NFD")[0];
			tmp = Utility.replaceHanonToSeion(tmp);
			r_str += tmp;
		})
		
		return r_str;
	}
	static replaceKanaToHira(str)
	{
		if(str == "")return str;
		const r_arr = str.split("");
		let r_str = "";
		r_arr.forEach((tmp)=>{
			
			// tmp = tmp.replace(/[ァ-ン]/g, function(s) {
			// 	return String.fromCharCode(s.charCodeAt(0) - 0x60);
			// });
			// tmp = tmp.normalize("NFD")[0];
			r_str += tmp;
		})
		
		return r_str;
	}
	static replaceRNtoBr(text)
	{
		return text.replace(/\r\n/g,"\r").replace("\r","<br/>");
	}
	static stripTags(text)
	{
		const div = document.createElement("div");
		div.innerHTML = text;
		return div.textContent;
	}
}
export class XMLUtility
{
	static getNodeValueFromTagName(node,tagName,type = "")
	{
		if(node.querySelector(tagName))
			return XMLUtility.parseNode(node.querySelector(tagName),type);
		return null


	}
	static parseNode(node,type = "")
	{
		let data = null;
		type = type == "" ? node.getAttribute("type") : type;
		node.removeAttribute("type");
		if(type == "boolean")
		{
			data = XMLUtility.parseBooleanNode(node)
		}
		else if(type == "int")
		{
			data = XMLUtility.parseIntNode(node);
		}
		else if(type == "float" || type == "second" || type == "minute")
		{
			data = XMLUtility.parseFloatNode(node,type);
		}
		else if(type == "array")
		{
			data = XMLUtility.parseArrayNode(node);
		}
		else if(type == "object")
		{
			data = XMLUtility.parseObjectNode(node);
		}
		else if(type == "date")
		{
			data = XMLUtility.parseDateNode(node);
		}
		else
		{
			data = XMLUtility.parseStringNode(node);
		}
		return data;
	}
	static parseBooleanNode(node)
	{
		return node.textContent === "1" ? true : false;
	}

	static parseStringNode(node)
	{
		let data = null;
		if(node.hasAttributes())
		{
			data = XMLUtility.parseNodeAsObject(node);
			if(node.getAttribute("as") == "html") data.value = node.innerHTML;
			else data.value = node.textContent;
			// data.value = node.innerHTML;
		}
		else
		{
			data = node.textContent;
		}
		return data;
	}
	static parseArrayNode(node)
	{
		let data = null;
		if(node.firstElementChild)
		{
			data = [];
			const t = node.firstElementChild.tagName,
				children = node.querySelectorAll(t);
			children.forEach((n,i)=>{
				data[i] = XMLUtility.parseNode(n);
			})
		}
		else
		{
			// console.log(node)
			if(node.hasAttributes())
			{
				data = XMLUtility.parseNodeAsObject(node);
				data.value = node.textContent.split(",");
			}
			else
			{
				// console.log(data,node)
				if(node.textContent.length > 0)
					data = node.textContent.split(",");
				else
					data = [];
			}
			
		}
		return data;
	}
	static parseIntNode(node)
	{
		let data = null;
		if(node.hasAttributes())
		{
			data = XMLUtility.parseNodeAsObject(node);
			data.value = parseInt(node.textContent);
		}
		else
		{
			data = parseInt(node.textContent);
		}
		return data;
	}
	static parseNodeAsObject(node)
	{
		const r = {};
		for(let i = 0;i < node.attributes.length;i++)
		{	
			r[node.attributes[i].name] = node.attributes[i].value;
		}

		return r;
	}
	static fixFloat(text,type)
	{
		if(type == "second")return  parseFloat(text) * 1000;
		else if(type == "minute")return parseFloat(text) * 1000 * 60;
		else return parseFloat(text)
	}
	static parseFloatNode(node,type)
	{
		let data = null;
		if(node.hasAttributes())
		{
			data = XMLUtility.parseNodeAsObject(node);
			data.value = XMLUtility.fixFloat(node.textContent,type);
		}
		else
		{
			data = XMLUtility.fixFloat(node.textContent,type);

		}
		return data;
	}
	static parseDateNode(node)
	{
		let data = null;
		if(node.hasAttributes())
		{
			data = XMLUtility.parseNodeAsObject(node);
			data.value = new Date(Date.parse(node.textContent));
		}
		else
		{
			if(node.textContent != "")
				data = new Date(Date.parse(node.textContent));
		}
		return data;
	}
	static parseObjectNode(node)
	{
		let elm = node.firstElementChild,
			data = {};
		while(elm)
		{
			data[elm.tagName] = XMLUtility.parseNode(elm);
			elm = elm.nextElementSibling;			
		}
		return data;
	}
}