<template>
	<div id="app" :class="[direction]">
		<transition name="fade">
			<div class="wrap" v-if="loaded">
				<header class="header">
					<p class="header__title"><img :src="require('@/assets/img/head_txt.svg')"/></p>
					<p class="header__hours">{{currentTime[2]}}<span>{{strHour}}<em>:</em>{{strMin}}</span></p>
				</header>
				<main class="mainList">
					<div class="mainList__page" v-for="arr,i in datalist.pageData" :class="{on:i == currentPage}">
						<MeetingInfo 
							v-for="data,j in arr"
							:key="data.id" 
							:data="data" 
							:style="{'--index':j}"
							class="info"
							:current="currentTime"
							:direction="direction"/>
					</div>
				</main>
			</div>
		</transition>
	</div>
</template>

<script>
const DAYS = "日月火水木金土";
import MeetingInfo from "@/components/MeetingInfo";
import {XMLSetting} from "@/assets/js/setting.js";
import {MeetingInfoDatalist} from "@/assets/js/datalist.js";
export default {
	components:{
		MeetingInfo
	},
	computed:{
		strHour:function(){
			return ("0" + this.currentTime[0]).slice(-2);
		},
		strMin:function(){
			return ("0" + this.currentTime[1]).slice(-2);
		}
	},
	async mounted(){
		
		const t = (()=>{
			const q = location.search.replace("?","").split("&");
			let id = "",
				direction = "";
			q.forEach((str)=>{
				const tmp = str.split("=");
				if(tmp[0] == "id") id = tmp[1];
				else if(tmp[0] == "direction") direction = tmp[1];
			});
			return [id,direction];
		})();
		this.id = t[0];
		this.direction = t[1] == "" ? "horizontal" : t[1];
		await this.init();
		setInterval(()=>{
			this.tick();
		},1000)
 	},
	data(){
		return {
			loaded:false,
			direction:"",
			datalist:null,
			bData:null,
			title:"",
			id:"",
			_tid:-1,
			currentPage:-1,
			currentTime:[-1,-1,""]
		}
	},
	methods:{
		init:async function(){
			this._setting = new XMLSetting();
			await this._setting.load("./setting.xml");

			const fid = this._setting.getDataByName("buildings").find((b)=> b.id == this.id);
			if(!fid) this.bData = this._setting.getDataByName("buildings");
			else this.bData = fid;

			this.id = this.bData.id;
			this.title = this.bData.value;
			this.duration = this._setting.getDataByName("duration") ? this._setting.getDataByName("duration") : 20 * 1000;
			console.log(this.duration)
			this.datalist = new MeetingInfoDatalist(this._setting.getDataByName("api-path"));
			await this.datalist.load(this.bData.en);
			this.tick();
			this.loaded = true;
			setTimeout(()=>{
				this.currentPage = 0;
				this.startPaging();
			},100)
			
		},
		intervalFunc:function()
		{
			const c = this.currentPage + 1 >= this.datalist.pageData.length ? 0 : this.currentPage + 1;

			this.currentPage = c;

		},
		startPaging:function()
		{
			clearInterval(this._tid);
			this.currentPage = 0;
			if(this.datalist.pageData.length > 1)
			{
				this._tid = setInterval(()=>{
					this.intervalFunc();
				},this.duration)
			}
		},
		tick:function(){
			const date = new Date(),
				h = date.getHours(),
				s = date.getSeconds(),
				m = date.getMinutes(),
				mn = date.getMonth() + 1,
				d = ("0" + date.getDate()).slice(-2),
				dy = DAYS.split("")[date.getDay()];
			

			if(this.currentTime[0] != h || this.currentTime[1] != m)
			{
				this.currentTime = [];
				// console.log(h,m,this.currentTime[0],this.currentTime[1])
				if(this.currentTime[0] != h)
				{
					this.currentTime[2] = mn + "/" + d +"(" + dy + ")";
				}
				this.currentTime[0] = h;
				this.currentTime[1] = m;

				const change = this.datalist.fix(this.currentTime);
				if(change)
				{
					this.startPaging();
				}

			}
		}
	}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto');
@keyframes blink{
	0%,100%{
		opacity: 1;
	}
	50%{
		opacity: 0;
	}
}
.fade-enter-active,.fade-leave-active{
	transition:opacity 1.4s ease-in;
}
.fade-enter,.fade-leave-to{
	opacity: 0;
}
#app {
	position: absolute;
	left:0;top:0;
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: cover;
	background-color: #1D2445;
	& .header{
		color: #0F1434;
		&__title{
			font-size: 60px;
			font-weight: bold;
		}
		&__hours{
			height: 58px;
			font-size: 34px;
			font-family: 'Roboto', sans-serif;
			& span{
				font-size: 67px;
				margin-left: 15px;
			}
			& em{
				font-style: normal;
				animation: blink 3s linear infinite;
			}
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	& .wrap{
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	& .mainList{
		position: relative;
		width: 100%;height: 100%;
		overflow: hidden;
		&__page{
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;	
			justify-content: flex-start;
			opacity: 0;
			transition: opacity .4s ease-in-out;

			& .info{
				opacity: 0;
				transform: translate(-20px,-20px);
				transition: transform .5s ease-out,opacity .5s ease-in;
				transition-delay: 0,0;
			}
			&.on{
				opacity: 1;
				& .info{
					transition-delay: calc(var(--index) * .1s),calc(var(--index) * .1s + .2s);
					opacity: 1;
					transform:none;
				}
			}
		}
		

	}
	&.horizontal{
		width: 1920px;
		height: 1080px;
		background-image: url("@/assets/img/v_bg.svg");
		& .header{
			height: 160px;
			padding: 0 40px;
		}
		& .mainList{
			padding:15px 30px;
			
			.mainList__page{
				width:1860px;
				gap:20px 0;
			}
		}
	}
	&.vertical{
		height: 1920px;
		width: 1080px;
		background-image: url("@/assets/img/h_bg.svg");
		& .header{
			height: 175px;
			padding: 0 40px;
		}
		& .mainList{
			padding:32px 40px;
			.mainList__page{
				width:1000px;
				gap:28px 0;	
			}
			
		}
	}
}
.header{
	width: 100%;
	background:#fff;
}
</style>
